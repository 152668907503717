<template>
  <div id="data-list-list-view">
  <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.push('/SurgeryMarkup') "
    />
    <div class="vx-row">
      <div class="vx-col lg:w-1/2">
        <vs-input
         type="number"
          min="0"
          class="w-full"
          label="Price From"
          v-model="SurgeryMarkupModel.PriceFrom"
        />
      </div>
      <div class="vx-col lg:w-1/2">
        <vs-input
          class="w-full"
           type="number"
          min="0"
          label="Price To"
          v-model="SurgeryMarkupModel.PriceTo"
        />
      </div>
    </div>

    <div class="vx-row">

  <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Service Type") }} </label>
        <v-select
          label="NameEN"

          class="w-full"
          v-model="SurgeryMarkupModel.MarkupServiceTypeID"
          :options="MarkupServiceTypes"
          :reduce="(ID) => ID.ID"
        />
      </div>

      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Surgery Categories ") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupSurgeryCategories"
          :options="SurgeryCategories"
          :reduce="(ID) => ID.ID"
        />
      </div>

    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Market source") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupPatientCountries"
          :options="patientCountries"
          :reduce="(ID) => ID.ID"
        />
      </div>
       <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Hospitals’ destination") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupHospitalCountries"
          :options="HospitalCountries"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>


     <div class="vx-row">
      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Specialties") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupSpecialties"
          :options="specialities"
          :reduce="(ID) => ID.ID"
        />
      </div>
       <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Hospitals") }} </label>
        <v-select
          label="Name"
             multiple

          class="w-full"
          v-model="MarkupHospitals"
          :options="Hospitals"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>


    <div class="vx-row">
       <div class="vx-col lg:w-1/2">
        <vs-input
         type="number"
          min="0"
          class="w-full "
          label="Markup Fee "
          v-model="SurgeryMarkupModel.MarkupFee"
        />
      </div>
       <div class="vx-col lg:w-1/2">

        <vs-checkbox

          color="success "
          class="mt-8"
          v-model="SurgeryMarkupModel.IsPercentage"
          > Is Percentage</vs-checkbox
        >
      </div>


    </div>
    <div class="vx-row mt-5 justify-end">
      <vs-button class="mr-4" type="flat" @click="$router.go(-1)"
        >back</vs-button>

      <vs-button color="primary" type="filled" @click="submitData"
        >Save</vs-button
      >
    </div>



  </div>
</template>
<script>
import moduleSurgeryMarkup from "@/store/SurgeryMarkup/moduleSurgeryMarkup.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import moduleSpecialty from '@/store/settings/specialty/moduleSpecialty.js';
import moduleSurgeryCategory from '@/store/settings/surgeryCategory/moduleSurgeryCategory.js';
export default {
  components: {  },
  data() {
    return {
      baseURL: domain,


      SurgeryMarkupModel: {
          MarkupSpecialties:[],
          MarkupHospitalCountries:[],
          MarkupPatientCountries:[],
          MarkupHospitals:[],
          MarkupSurgeryCategories:[],
          IsPercentage:false,



      },

    };
  },
  methods: {


    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
         this.SurgeryMarkupModel.MarkupPatientCountries = this.$store.state.SurgeryMarkupList.MarkupPatientCountries.map(
        function(val) {
          var obj = {};
          obj.CountryID = val;
          return obj;
        }
      );

           this.SurgeryMarkupModel.MarkupHospitalCountries = this.$store.state.SurgeryMarkupList.MarkupHospitalCountries.map(
        function(val) {
          var obj = {};
          obj.CountryID = val;
          return obj;
        }
        );
     this.SurgeryMarkupModel.MarkupHospitals = this.$store.state.SurgeryMarkupList.MarkupHospitals.map(
        function(val) {
          var obj = {};
          obj.HospitalID = val;
          return obj;
        }
        );

     this.SurgeryMarkupModel.MarkupSpecialties = this.$store.state.SurgeryMarkupList.MarkupSpecialties.map(
        function(val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        }
        );
     this.SurgeryMarkupModel.MarkupSurgeryCategories = this.$store.state.SurgeryMarkupList.MarkupSurgeryCategories.map(
        function(val) {
          var obj = {};
          obj.SurgeryCategoryID = val;
          return obj;
        }
        );
    debugger
          if (this.SurgeryMarkupModel.ID !== null && this.SurgeryMarkupModel.ID >= 0) {

            this.$store
              .dispatch("SurgeryMarkupList/UpdateSurgeryMarkup", this.SurgeryMarkupModel).then(res=>{
                window.showUpdateSuccess();
                  this.$router.go(-1)
              })
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete this.SurgeryMarkupModel.ID;
            this.$store
              .dispatch("SurgeryMarkupList/AddSurgeryMarkup", this.SurgeryMarkupModel).then(res=>{
                window.showUpdateSuccess();

                  this.$router.go(-1)
              })
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }
        }
      });
    }

  },
  computed: {

    patientCountries() {
      return this.$store.state.CountryList.countries;
    },
    HospitalCountries() {
      return this.$store.state.CountryList.countries;
    },
    SurgeryCategories() {
      return this.$store.state.CountryList.countries;
    },
    MarkupServiceTypes(){
      return this.$store.state.SurgeryMarkupList.MarkupServiceTypes;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    specialities() {
      return this.$store.state.SpecialtyList.specialities.filter(x=>x.ParentSpecialtyID == null);

    },
    SurgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
 MarkupPatientCountries: {
      get() {
        return this.$store.state.SurgeryMarkupList.MarkupPatientCountries;
      },
      set(newValue) {
        this.$store.commit("SurgeryMarkupList/SET_MarkupPatientCountries", newValue);
      }},

 MarkupHospitalCountries: {
      get() {
        return this.$store.state.SurgeryMarkupList.MarkupHospitalCountries;
      },
      set(newValue) {
        this.$store.commit("SurgeryMarkupList/SET_MarkupHospitalCountries", newValue);
      }
    },
 MarkupHospitals: {
      get() {
        return this.$store.state.SurgeryMarkupList.MarkupHospitals;
      },
      set(newValue) {
        this.$store.commit("SurgeryMarkupList/SET_MarkupHospitals", newValue);
      }
    },
 MarkupSpecialties: {
      get() {
        return this.$store.state.SurgeryMarkupList.MarkupSpecialties;
      },
      set(newValue) {
        this.$store.commit("SurgeryMarkupList/SET_MarkupSpecialties", newValue);
      }
    },
 MarkupSurgeryCategories: {
      get() {
        return this.$store.state.SurgeryMarkupList.MarkupSurgeryCategories;
      },
      set(newValue) {
        this.$store.commit("SurgeryMarkupList/SET_MarkupSurgeryCategories", newValue);
      }
    },
  },
  created() {
    if (!moduleSurgeryMarkup.isRegistered) {
      this.$store.registerModule("SurgeryMarkupList", moduleSurgeryMarkup);
      moduleSurgeryMarkup.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
      this.$store.dispatch("HospitalList/GetAllHospitals", {});
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");
    this.$store.dispatch("SurgeryMarkupList/GetAllMarkupServiceTypes");
    var SurgeryMarkupId = this.$route.params.Id;
    if (SurgeryMarkupId) {
      this.$store
        .dispatch("SurgeryMarkupList/GetSurgeryMarkup", SurgeryMarkupId)
        .then((res) => {
          this.SurgeryMarkupModel = res.data.Data;
          debugger;
         this.$store.commit(
       "SurgeryMarkupList/SET_MarkupPatientCountries",
          this.SurgeryMarkupModel.PatiantCountries.map(
          a => a.CountryID
         )
        );
         this.$store.commit(
       "SurgeryMarkupList/SET_MarkupHospitalCountries",
          this.SurgeryMarkupModel.HospitalCountries.map(
          a => a.CountryID
         )
        );
         this.$store.commit(
       "SurgeryMarkupList/SET_MarkupSpecialties",
          this.SurgeryMarkupModel.Specialties.map(
          a => a.SpecialtyID
         )
        );
         this.$store.commit(
       "SurgeryMarkupList/SET_MarkupHospitals",
          this.SurgeryMarkupModel.Hospitals.map(
          a => a.HospitalID
         )
        );
         this.$store.commit(
       "SurgeryMarkupList/SET_MarkupSurgeryCategories",
          this.SurgeryMarkupModel.SurgeryCategories.map(
          a => a.SurgeryCategoryID
         )
        );
    })

    }

  },
};
</script>
<style scoped>
.v-select {
  background: white;
}
.quill-editor {
  background: white;
}
.ql-snow {
  background: white;
}
</style>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
